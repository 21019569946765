
.c-nenpyos {
  position: relative;
  min-width: 100%;
  display: inline-block;
  margin-top: $header-height;

  >.e-years {
 }    //c-year-lines

  >.e-containers {
    position: relative;
    z-index: 2;
    margin-left: $year-width;  //c-year-lines の e-yearのため
    display: flex;
    justify-content: flex-start;

    >.e-col {
      flex: 1 0 auto;
      width: $column-width;
      margin-right: 1rem;
      position: relative;
      background: yellow;

      >.e-navi {
        position: sticky;
        top: 0;
        background: red; } } } }


