
.c-popup-nenpyo { //c-nenpyos>.e-containers>.e-col の中
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;


  &.is-shown {
    display: block; }

  >.e-shadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.4);

    pointer-events: auto; }

  >.e-nenpyo-wrapper {
    position: absolute;
    top: 2rem;
    left: 0;
    right: 0;
    bottom: 2rem;

    max-width: 100rem;

    margin: auto;
    pointer-events: none;
    text-align: center; //子要素がinline-blockなのでセンタリングする
    display: flex;
    flex-direction: column;


    >.e-header {
      flex: 0 0 auto; }

    >.e-contents {
      flex: 1 1 auto;
      height: 0px; //なんかこれがないと長さが延々と長くなる
      pointer-events: auto;

      display: flex;

      margin-left: 1rem;
      margin-right: 1rem;
      text-align: left; //親要素の centerを解除

      >.e-nenpyo {
        flex: 1 0 $column-width;

        position: relative;
        margin-left: -1rem;

        max-width: 100%;
        min-width:  $column-width;

        overflow: scroll;

        @include mq(sp) {
          display: none; }


        >.e-header {
          position: sticky;
          top: 0;
          z-index: 2;

          background: #aaa;
          border-top-left-radius: $border-radius;
          border-top-right-radius: $border-radius;
          height: $header-height;

          >.e-title {
            margin-left: 1rem;
            line-height: $header-height; } }


        >.e-list {
          display: flex;
          flex-direction: column;

          >.e-item {
            display: flex;

            &:hover {
              >.e-year {
                background: yellow; }
              >.e-content {
                overflow: visible;
                z-index: 1;
                >.e-paper {
                  background: yellow;
                  box-shadow: 0 2px 3px rgba(0,0,0,0.3); } } }

            >.e-year {
              flex: 0 0 auto;
              width: 5rem;
              background: #ddd;
              padding: .5rem;
              font-size: 1.2rem;
              color: #aaa; }

            >.e-content {
              flex: 1 1 auto;
              overflow: hidden;
              position: relative;


              >.e-paper {
                padding: .5rem;
                background: white; } } } } }

      >.e-events {
        flex: 1 1 auto;
        background: white;
        border-left: 1px solid #eee;

        overflow: scroll;
        position: relative; //offset の基準となるため。 子要素を

        padding-bottom: 90vh;

        >.e-detail {
          display: flex;
          flex-direction: column;
          >.e-desc {
            flex: 1 1 auto;
            padding: 2rem;

            >.e-sources {
              list-style-type: none;
              display: flex;
              justify-content: flex-end;
              margin: .5rem 0;

              >.e-item {

                >.e-source {
                  $gray: #888;
                  display:  inline-flex;
                  float: right;
                  border: solid 1px $gray;
                  color: $gray;
                  border-radius: 3px;
                  font-size: 1.4rem;
                  text-decoration: none;
                  margin-left: .5rem;

                  >.e-title {
                    padding: 3px; }

                  >.e-anchor {
                    padding: 3px;
                    background: $gray;
                    color: white; } } } } } } } } } }
