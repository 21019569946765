
html {
  font-size: 62.5%; }



body {
  font-size: 1.6rem;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  line-height: 1; }


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

h1,h2,h3,h4,h5,h6 {
  font-size: inherit; }



@import "./sass/foundation/_variables";
@import "./sass/foundation/_responsive";

@import "./sass/component/_page-wrapper";
@import "./sass/component/_page-wrapper";
@import "./sass/component/_nenpyos";
@import "./sass/component/_sub-nenpyo";
@import "./sass/component/_popup-nenpyo";
@import "./sass/component/_event-header";
@import "./sass/component/_year-icon";
@import "./sass/component/_event-line";
@import "./sass/component/_nenpyo-header";
@import "./sass/component/_nenpyo-header-new";
@import "./sass/component/_year-lines";
