

.c-event-line{

  display: flex;
  flex-direction: column;
  border-left: $line-width solid $line-green;
  margin-left: $circle-size; //丸が大きくなってもはみでないサイズ

  //一番下の要素も、上までスクロールできるようにする
  &.m-scroll-full{
    padding-bottom: 90vh;
  }
  &.m-bg-white{
    background: white;
  }

  >.e-item{

    list-style: none;
    height: $item-height;

    &.m-height-auto{
      height: auto;
    }

    .e-event-item{

      height: 100%;

      width: 100%;
      text-decoration: none; // linkになりうるのでキャンセルしておく

      position: relative;

      //年&contentが入るitemにホバーしたら、yearがハイライト
      &.m-active,
      &:hover{

        >.e-year>.c-year-icon{
          @extend .m-big;
        }
        >.e-content{
          overflow: visible;
          z-index: 1;

          >.e-num{
            display: none;
          }
          >.e-hider{
            overflow-y: visible;
            display: block;

            >.e-fade-shadow{
              display: none;
            }

            >.e-paper{
              box-shadow: 0px 1px 3px rgba(0,0,0,0.4);

              &.m-hover-slide{
                padding-left: 1rem
              }
            }
          }
        }
      }

      >.e-year{

        position: relative;
        top: $circle-size/2;
        left: -$line-width/2;

        >.c-year-icon{
          @extend .m-center;
        }
          

        
      }

      >.e-content{
        margin-left: 2rem;
        //overflow がよくわからない
        overflow-x: visible ;
        position: relative;
        height: 100%;

        >.e-num{
          position: absolute;
          left: .2rem;
          bottom: .2rem;

          background: rgba(#000, 0.5);
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          line-height: 2rem;
          text-align: center;
          color: white;
        }

        >.e-hider{
          overflow-y: hidden ;
          height: 100%;
          position: relative;
          display: inline-block;

          &.m-show{
            overflow-y: visible;
          }


          //これをしないとガクツキがおこる
          &.m-wrap{
            display: block;
          }


          >.e-fade-shadow{ //徐々に透明になるグラデーション
            content: " ";
            display: block;
            position: absolute;
            height: 1em;
            bottom: 0;
            left: 0;
            right: 0;
            background: transparent;
            background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 34%, rgba(255,255,255,0) 100%);
          }
            
          >.e-paper{

            background: white;
            padding: 0;

            transition: 1s;
            display: inline-block;
            min-width: 100%;
            
            &.m-hover-slide{
              //hover されたときに、paddinig-leftにより右にちょっとずれる

            }

            >.e-event{

              line-height: 1;
              padding: .5rem .5rem ;
              background: white;
              display: block;
              color: inherit;
              text-decoration: none;

              white-space: nowrap;
              overflow: hidden;
              border-bottom: 1px solid #eee;


              &.m-title{
                text-align: center;
                font-weight: bold;
              }

              //改行する
              &.m-wrap{
                white-space: normal;
              }


              &.m-hoverable:hover{
                background: yellow;
              }

              &.m-active,
              &.m-hoverable.m-active{
                background: $light-green
              }

              &:last-child{
                border-bottom: 0;
              }
            }
          }
        }
      }
    }
  }


}

