


.c-nenpyo-header {
  position: relative;
  z-index: 2;

  width: 100%;
  height: 100%;

  background: #aaa;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  height: $header-height;

  display: flex;

  >.e-title {
    margin-left: 1rem;
    line-height: $header-height;
    white-space: nowrap;
    overflow: hidden; }

  >.e-link {
    flex: none;
    margin: auto 3px auto auto;

    $button-size: $header-height * 0.8;
    width: $button-size;
    height: $button-size;
    line-height: $button-size;

    text-align: center;
    border-radius: 50%;

    background: black;
    color: white;
    text-decoration: none;
    line-height: {} } }
