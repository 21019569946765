$breakpoints: (
  'sp': 'screen and (max-width: 680px)',
  'pc': 'screen and (min-width: 681px)',
  'ie': 'all and (-ms-high-contrast: none)',
) !default;

@mixin mq($breakpoint: pc) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
