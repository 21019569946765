
.c-page-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  >.e-header {
    flex: 0 0 auto;
    background: black;
    color: white; }

  >.e-content {
    flex: 1 1 auto;
    position: relative;

    >.e-scroller {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      overflow: auto;
      -webkit-overflow-scrolling: touch; }


    >.e-fixed {
      pointer-events: none;

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; } }


  >.e-footer {
    margin-top:auto {}
    flex: 0 0 auto;
    background: black;
    color: white; } }
