
.c-year-lines{
  position: absolute;
  z-index: 1;
  background: white;
  top: 0;
  left: 0;
  right: 0;
    
  >.e-list{

    >.e-line{

      line-height: 1;
      height: $item-height;
      border-bottom: 1px solid #eee;
      font-size: 1.2rem;

      >.e-year{

        position: sticky ;
        left: 0;
        z-index: 300;

        display: block;
        height: 100%;
        padding: .5rem;

        width: $year-width;
        background: rgba(#fff, 0.8);
      }


        // color: #aaa
      >.e-content{

      }

    }
  }
}